var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { CourseModal } from '../../components/modals/course';
import React, { useState } from 'react';
import { ListView } from '../../components';
import { useNavigate } from 'react-router-dom';
import { ConfirmDelete } from '../../components/modals/confirm-delete';
export var CourseView = function () {
    var navigate = useNavigate();
    var _a = useState(false), confirm = _a[0], setConfirm = _a[1];
    var _b = useState(null), modalOpen = _b[0], openModal = _b[1];
    var data = useQuery(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query GetCourses{\n            courses {\n                id\n                name\n\n                shortDescription\n                description\n            }\n        }\n    "], ["\n        query GetCourses{\n            courses {\n                id\n                name\n\n                shortDescription\n                description\n            }\n        }\n    "])))).data;
    var createCourse = useMutation(gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        mutation CreateCourse($input: CourseInput){\n            createCourse(input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation CreateCourse($input: CourseInput){\n            createCourse(input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourses']
    })[0];
    var deleteCourse = useMutation(gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        mutation DeleteCourse($id: ID){\n            deleteCourse(id: $id){\n                id\n            }\n        }\n    "], ["\n        mutation DeleteCourse($id: ID){\n            deleteCourse(id: $id){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourses']
    })[0];
    var updateCourse = useMutation(gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        mutation UpdateCourse($id: ID, $input: CourseInput){\n            updateCourse(id: $id, input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation UpdateCourse($id: ID, $input: CourseInput){\n            updateCourse(id: $id, input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourses']
    })[0];
    var courses = (data === null || data === void 0 ? void 0 : data.courses) || [];
    return (_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: [_jsx(ConfirmDelete, { open: confirm, onClose: function () { setConfirm(false); }, onSubmit: function () {
                    deleteCourse === null || deleteCourse === void 0 ? void 0 : deleteCourse({
                        variables: {
                            id: modalOpen.id
                        }
                    }).then(function () {
                        openModal(null);
                        setConfirm(false);
                    });
                    // deleteUser({variables: {id: modalOpen?.id}}).then(() => {
                    //     openModal(null);
                    //     setConfirm(false);
                    // })
                } }), _jsx(CourseModal, { open: Boolean(modalOpen), selected: modalOpen, onDelete: (modalOpen === null || modalOpen === void 0 ? void 0 : modalOpen.id) && (function () {
                    setConfirm(true);
                }), onSubmit: function (course) {
                    if (course.id) {
                        updateCourse({
                            variables: {
                                id: course.id,
                                input: {
                                    name: course.name,
                                    shortDescription: course.shortDescription,
                                    description: course.description
                                }
                            }
                        }).then(function () {
                            openModal(null);
                        });
                    }
                    else {
                        createCourse({ variables: {
                                input: {
                                    name: course.name,
                                    shortDescription: course.shortDescription,
                                    description: course.description
                                }
                            } }).then(function () {
                            // refetch
                            openModal(null);
                        });
                    }
                }, onClose: function () {
                    openModal(null);
                } }), _jsx(ListView, { cols: [
                    {
                        key: 'name',
                        label: "Name"
                    },
                    {
                        key: 'createdAt',
                        label: "Created on"
                    },
                ], onEdit: function (item) {
                    openModal(item);
                }, onSelect: function (item) {
                    navigate("".concat(item.id, "/editor"));
                }, onCreate: function () {
                    openModal({});
                }, rows: courses })] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
