var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DialogContent, DialogTitle } from '@mui/material';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Dialog } from '@mui/material';
import React from 'react';
export var ConfirmDelete = function (props) {
    return (_jsxs(Dialog, __assign({ onClose: props.onClose, open: props.open }, { children: [_jsx(DialogTitle, { children: "Confirm Delete" }), _jsx(DialogContent, __assign({ sx: { padding: 0 } }, { children: _jsx(Typography, __assign({ sx: { margin: '12px' } }, { children: "Are you sure you want to delete this item?" })) })), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: props.onClose }, { children: "No" })), _jsx(Button, __assign({ onClick: props.onSubmit, color: "error", variant: "contained" }, { children: "Yes" }))] })] })));
};
