var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
export var UserModal = function (props) {
    var _a = useState({}), user = _a[0], setUser = _a[1];
    useEffect(function () {
        setUser(__assign({}, props.selected));
    }, [props.selected]);
    var onSubmit = function () {
        var _a;
        (_a = props.onSubmit) === null || _a === void 0 ? void 0 : _a.call(props, user);
    };
    return (_jsxs(Dialog, __assign({ fullWidth: true, open: props.open, onClose: props.onClose }, { children: [_jsx(DialogTitle, { children: "User" }), _jsxs(DialogContent, __assign({ sx: { display: 'flex', marginTop: '6px', flexDirection: 'column' } }, { children: [_jsx(TextField, { value: user.name, onChange: function (e) { return setUser(__assign(__assign({}, user), { name: e.target.value })); }, size: 'small', sx: { margin: '6px' }, label: "Name" }), _jsx(TextField, { value: user.email, onChange: function (e) { return setUser(__assign(__assign({}, user), { email: e.target.value })); }, size: 'small', sx: { margin: '6px' }, label: "E-mail" }), _jsx(TextField, { value: user.password, onChange: function (e) { return setUser(__assign(__assign({}, user), { password: e.target.value })); }, size: "small", sx: { margin: '6px' }, label: "Password", type: "password" })] })), _jsxs(DialogActions, { children: [_jsx(Box, __assign({ sx: { flex: 1 } }, { children: props.onDelete && _jsx(Button, __assign({ onClick: props.onDelete, color: "error" }, { children: "Delete" })) })), _jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', justifyContent: 'flex-end' } }, { children: [_jsx(Button, __assign({ onClick: props.onClose }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: onSubmit, variant: "contained" }, { children: "Save" }))] }))] })] })));
};
