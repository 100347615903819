var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloudUpload } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box, Paper } from '@mui/material';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
export var ImageUpload = function (props) {
    var onDrop = useCallback(function (acceptedFiles) {
        var _a;
        // Do something with the files
        console.log({ acceptedFiles: acceptedFiles });
        if (acceptedFiles === null || acceptedFiles === void 0 ? void 0 : acceptedFiles[0]) {
            (_a = props.onUpload) === null || _a === void 0 ? void 0 : _a.call(props, acceptedFiles === null || acceptedFiles === void 0 ? void 0 : acceptedFiles[0]);
            // setPreview(URL.createObjectURL(acceptedFiles?.[0]))
        }
    }, [props.onUpload]);
    var _a = useDropzone({ onDrop: onDrop, accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'] } }), getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, isDragActive = _a.isDragActive;
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsxs(Paper, __assign({ elevation: 3 }, getRootProps(), { sx: {
                    width: 100,
                    overflow: 'hidden',
                    cursor: 'pointer',
                    height: 100,
                    opacity: isDragActive ? '0.6' : '1',
                    display: 'flex'
                } }, { children: [_jsx("input", __assign({}, getInputProps())), props.src ? (_jsx("img", { style: { height: '100%', width: '100%' }, src: props.src, alt: "" })) : (_jsx(Box, __assign({ sx: { display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 } }, { children: _jsx(CloudUpload, { fontSize: 'large' }) })))] })), _jsx(Typography, __assign({ sx: { alignSelf: 'center' }, fontSize: 'small' }, { children: "Course image" }))] })));
};
