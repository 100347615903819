var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider } from '@mui/material';
import React from 'react';
import { TreeView, TreeItem } from '@mui/lab';
import { Add, ChevronLeft, ChevronRight, ExpandMore } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import { Paper } from '@mui/material';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { InfoEditor } from './info-editor';
import { LessonEditor } from './lesson-editor';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Header } from '../../components/header';
export var CourseEditor = function () {
    var _a, _b, _c, _d;
    var id = useParams().id;
    var navigate = useNavigate();
    var data = useQuery(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query GetCourse($id: String){\n            courses(where: {ids: [$id]}){\n                id\n                name\n\n                image\n\n                shortDescription\n                description\n\n                stages {\n                    id\n                    name\n\n                    video\n\n                    index\n\n                    questions {\n                        id\n                        index\n                        text\n\n                        answer {\n                            id\n                        }\n\n                        answers {\n                            id\n                            index\n                            text\n                        }\n                    }\n                }\n\n                outcomes {\n                    id\n                    index\n                    text\n                }\n            }\n        }\n    "], ["\n        query GetCourse($id: String){\n            courses(where: {ids: [$id]}){\n                id\n                name\n\n                image\n\n                shortDescription\n                description\n\n                stages {\n                    id\n                    name\n\n                    video\n\n                    index\n\n                    questions {\n                        id\n                        index\n                        text\n\n                        answer {\n                            id\n                        }\n\n                        answers {\n                            id\n                            index\n                            text\n                        }\n                    }\n                }\n\n                outcomes {\n                    id\n                    index\n                    text\n                }\n            }\n        }\n    "]))), {
        variables: {
            id: id
        }
    }).data;
    var createLesson = useMutation(gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        mutation CreateLesson($course: ID, $input: CourseStageInput){\n            createCourseStage(course: $course, input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation CreateLesson($course: ID, $input: CourseStageInput){\n            createCourseStage(course: $course, input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var course = ((_a = data === null || data === void 0 ? void 0 : data.courses) === null || _a === void 0 ? void 0 : _a[0]) || {};
    return (_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column', background: '#eefaff', width: '100vw', height: '100vh' } }, { children: [_jsx(Header, {}), _jsxs(Box, __assign({ sx: { flex: 1, display: 'flex' } }, { children: [_jsxs(Paper, { children: [_jsxs(Box, __assign({ onClick: function () { return navigate('/certificates'); }, sx: {
                                    paddingTop: '6px',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    alignItems: 'center'
                                } }, { children: [_jsx(IconButton, __assign({ size: "small" }, { children: _jsx(ChevronLeft, { fontSize: "inherit" }) })), _jsx(Typography, { children: "Leave editor" })] })), _jsx(Divider, { sx: { marginTop: '6px', marginBottom: '6px' } }), _jsxs(TreeView, __assign({ onNodeSelect: function (node, nodeIds) {
                                    console.log({ nodeIds: nodeIds });
                                    if (nodeIds === 'info') {
                                        navigate('.');
                                    }
                                    else if (nodeIds !== 'stages' && nodeIds.indexOf('stage') === 0) {
                                        var stageId = nodeIds === null || nodeIds === void 0 ? void 0 : nodeIds.replace('stage.', '');
                                        navigate("lesson/".concat(stageId));
                                    }
                                }, defaultExpandIcon: _jsx(ChevronRight, {}), defaultCollapseIcon: _jsx(ExpandMore, {}) }, { children: [_jsx(TreeItem, { sx: { '& .MuiTreeItem-content': { padding: 0 } }, nodeId: "info", label: "Course info" }), _jsx(TreeItem, __assign({ sx: { '& .MuiTreeItem-content': { padding: 0 } }, nodeId: "stages", label: (_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, __assign({ sx: { flex: 1 } }, { children: "Course lessons" })), _jsx(IconButton, __assign({ sx: { marginLeft: '12px' }, onClick: function (e) {
                                                        e.stopPropagation();
                                                        createLesson({ variables: { course: id, input: { name: 'New lesson' } } });
                                                    }, size: "small" }, { children: _jsx(Add, { fontSize: 'inherit' }) }))] }))) }, { children: (_d = (_c = (_b = course === null || course === void 0 ? void 0 : course.stages) === null || _b === void 0 ? void 0 : _b.slice()) === null || _c === void 0 ? void 0 : _c.sort(function (a, b) { return a.index - b.index; })) === null || _d === void 0 ? void 0 : _d.map(function (stage) {
                                            var _a, _b, _c;
                                            return (_jsx(TreeItem, { sx: { '& .MuiTreeItem-content': { marginBottom: '6px', paddingRight: '12px' } }, nodeId: "stage.".concat(stage.id), label: "".concat((_b = (_a = stage.name) === null || _a === void 0 ? void 0 : _a.substring(0, 20)) === null || _b === void 0 ? void 0 : _b.trim()).concat(((_c = stage.name) === null || _c === void 0 ? void 0 : _c.length) > 20 ? '...' : '') }));
                                        }) }))] }))] }), _jsx(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column', padding: '6px' } }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "", element: _jsx(InfoEditor, { course: course }) }), _jsx(Route, { path: "lesson/:lesson_id", element: _jsx(LessonEditor, { course: course }) })] }) }))] }))] })));
};
var templateObject_1, templateObject_2;
