var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { API_URL } from '../../App';
import React from 'react';
import LOGO from '../../assets/logo.svg';
export var Header = function () {
    var logout = function () {
        return fetch("".concat(API_URL, "/api/auth/logout"), { method: "POST", credentials: 'include' }).then((function () {
            window.location.href = "/";
        }));
    };
    return (_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#0A1229', padding: '12px' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx("img", { alt: "ILL Logo", style: { height: '30px', padding: '6px' }, src: LOGO }), _jsx(Typography, __assign({ sx: { marginLeft: '12px', color: '#fff' } }, { children: "Admin" }))] })), _jsx(Button, __assign({ onClick: logout, size: "small", variant: "contained", color: "secondary" }, { children: "Logout" }))] })));
};
