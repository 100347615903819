var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { Add, MoreVert } from '@mui/icons-material';
export var ListView = function (props) {
    var _a, _b;
    return (_jsx(Paper, __assign({ sx: { overflow: 'hidden', flex: 1 } }, { children: _jsxs(Table, { children: [_jsx(TableHead, __assign({ sx: { bgcolor: "primary.main" } }, { children: _jsxs(TableRow, { children: [(_a = props.cols) === null || _a === void 0 ? void 0 : _a.map(function (col) { return (_jsx(TableCell, __assign({ sx: { color: 'secondary.main' } }, { children: col.label }))); }), props.onCreate !== undefined && (_jsx(TableCell, __assign({ align: 'right', size: 'small' }, { children: _jsx(IconButton, __assign({ onClick: props.onCreate, color: "secondary" }, { children: _jsx(Add, {}) })) })))] }) })), _jsx(TableBody, __assign({ sx: { overflow: 'auto' } }, { children: (_b = props.rows) === null || _b === void 0 ? void 0 : _b.map(function (row) {
                        var _a;
                        return (_jsxs(TableRow, __assign({ hover: Boolean(props.onCreate), sx: {
                                cursor: Boolean(props.onCreate) ? 'pointer' : undefined
                            }, onClick: function () { var _a; return (_a = props.onSelect) === null || _a === void 0 ? void 0 : _a.call(props, row); } }, { children: [(_a = props.cols) === null || _a === void 0 ? void 0 : _a.map(function (col) { return (_jsx(TableCell, { children: row === null || row === void 0 ? void 0 : row[col.key] })); }), (props.onCreate || props.onEdit) && (_jsx(TableCell, __assign({ align: 'right', size: "small" }, { children: props.onEdit && (_jsx(IconButton, __assign({ sx: { zIndex: 9 }, onClick: function (e) {
                                            var _a;
                                            e.stopPropagation();
                                            (_a = props.onEdit) === null || _a === void 0 ? void 0 : _a.call(props, row);
                                        } }, { children: _jsx(MoreVert, {}) }))) })))] })));
                    }) }))] }) })));
};
