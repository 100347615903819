var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paper } from '@mui/material';
import { Box } from '@mui/material';
import { VideoUpload } from '../../components/video-upload';
import React, { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../App';
import axios from 'axios';
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { IconButton } from '@mui/material';
import { Add, ChevronLeft, ChevronRight, Delete } from '@mui/icons-material';
import { debounce } from 'lodash';
import { Checkbox } from '@mui/material';
import { ConfirmDelete } from '../../components/modals/confirm-delete';
import { useConfirm } from 'edutech-admin/src/hooks/useConfirmModal';
export var LessonEditor = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var client = useApolloClient();
    var _k = useState(false), confirm = _k[0], setConfirm = _k[1];
    var _l = useState(''), lessonName = _l[0], setLessonName = _l[1];
    var _m = useState(''), title = _m[0], setTitle = _m[1];
    var _o = useState([]), answers = _o[0], setAnswers = _o[1];
    var _p = useState(false), editQuestion = _p[0], setEditQuestions = _p[1];
    var _q = useState(0), activeQuestion = _q[0], setActiveQuestion = _q[1];
    var lesson_id = useParams().lesson_id;
    var stage = (_b = (_a = props.course) === null || _a === void 0 ? void 0 : _a.stages) === null || _b === void 0 ? void 0 : _b.find(function (a) { return a.id === lesson_id; });
    var question = ((_e = (_d = (_c = stage === null || stage === void 0 ? void 0 : stage.questions) === null || _c === void 0 ? void 0 : _c.slice()) === null || _d === void 0 ? void 0 : _d.sort(function (a, b) { return a.index - b.index; })) === null || _e === void 0 ? void 0 : _e[activeQuestion]) || {};
    var navigate = useNavigate();
    useEffect(function () {
        setLessonName(stage === null || stage === void 0 ? void 0 : stage.name);
    }, [stage === null || stage === void 0 ? void 0 : stage.name]);
    useEffect(function () {
        console.log({ question: question });
        setTitle(question === null || question === void 0 ? void 0 : question.text);
    }, [question === null || question === void 0 ? void 0 : question.text, activeQuestion]);
    useEffect(function () {
        setAnswers((question === null || question === void 0 ? void 0 : question.answers) || []);
    }, [question === null || question === void 0 ? void 0 : question.answers, activeQuestion]);
    var uploadVideo = function (file) {
        var _a;
        var fd = new FormData();
        fd.append('video', file);
        //TODO show loading indicator
        return axios.post("".concat(API_URL, "/api/").concat((_a = props.course) === null || _a === void 0 ? void 0 : _a.id, "/").concat(lesson_id, "/upload"), fd, {
            withCredentials: true,
            onUploadProgress: function (progress) {
                setUploadProgress((progress.loaded / (progress.total || 0)) * 100);
            }
        }).catch(function (err) {
            setUploadProgress('Error uploading video');
        }).then(function () {
            setTimeout(function () { return setUploadProgress(null); }, 2000);
        });
        // return fetch(`${API_URL}/api/${id}/${activeView}/upload`, {body: fd}).then(() => {
        //     console.log("Uploaded")
        // })
    };
    var deleteLesson = useMutation(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        mutation DeleteLesson ($course: ID, $stage: ID){\n            deleteCourseStage(course: $course, stage: $stage){\n                id\n            }\n        }\n    "], ["\n        mutation DeleteLesson ($course: ID, $stage: ID){\n            deleteCourseStage(course: $course, stage: $stage){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var _updateLessonName = useMutation(gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        mutation UpdateLessonName($course: ID, $stage: ID, $input: CourseStageInput){\n            updateCourseStage(course: $course, stage: $stage, input: $input){\n                id\n            }        \n        }\n    "], ["\n        mutation UpdateLessonName($course: ID, $stage: ID, $input: CourseStageInput){\n            updateCourseStage(course: $course, stage: $stage, input: $input){\n                id\n            }        \n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var debounceUpdateLessonName = useMemo(function () { return debounce(_updateLessonName, 400); }, []);
    var updateLessonName = function (name) {
        var _a;
        debounceUpdateLessonName({
            variables: {
                course: (_a = props === null || props === void 0 ? void 0 : props.course) === null || _a === void 0 ? void 0 : _a.id,
                stage: stage === null || stage === void 0 ? void 0 : stage.id,
                input: {
                    name: name
                }
            }
        });
        setLessonName(name);
    };
    var createQuestion = useMutation(gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        mutation CreateQuestion($stage: ID, $input: CourseStageQuestionInput){\n            createCourseStageQuestion(stage: $stage, input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation CreateQuestion($stage: ID, $input: CourseStageQuestionInput){\n            createCourseStageQuestion(stage: $stage, input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var _updateQuestion = useMutation(gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        mutation UpdateQuestion($stage: ID, $id: ID, $input: CourseStageQuestionInput){\n            updateCourseStageQuestion(stage: $stage, question: $id, input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation UpdateQuestion($stage: ID, $id: ID, $input: CourseStageQuestionInput){\n            updateCourseStageQuestion(stage: $stage, question: $id, input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var debouncedQuestionUpdate = useMemo(function () { return debounce(_updateQuestion, 400); }, []);
    var updateQuestion = function (text, answer) {
        debouncedQuestionUpdate({
            variables: {
                stage: stage === null || stage === void 0 ? void 0 : stage.id,
                id: question === null || question === void 0 ? void 0 : question.id,
                input: {
                    text: text,
                    answer: answer
                }
            }
        });
        if (text)
            setTitle(text);
    };
    var deleteQuestion = useMutation(gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        mutation DeleteQuestion($stage: ID, $id: ID){\n            deleteCourseStageQuestion(stage: $stage, question: $id){\n                id\n            }\n        }\n    "], ["\n        mutation DeleteQuestion($stage: ID, $id: ID){\n            deleteCourseStageQuestion(stage: $stage, question: $id){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var _r = useConfirm("", deleteQuestion), ConfirmDeleteQuestionDialog = _r[0], confirmedDeleteQuestion = _r[1];
    var createAnswer = useMutation(gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        mutation CreateAnswer($question: ID, $input: CourseStageAnswerInput){\n            createCourseStageAnswer(question: $question, input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation CreateAnswer($question: ID, $input: CourseStageAnswerInput){\n            createCourseStageAnswer(question: $question, input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var deleteAnswer = useMutation(gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        mutation DeleteAnswer($question: ID, $id: ID){\n            deleteCourseStageAnswer(question: $question, answer: $id){\n                id\n            }\n        }\n    "], ["\n        mutation DeleteAnswer($question: ID, $id: ID){\n            deleteCourseStageAnswer(question: $question, answer: $id){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var _s = useConfirm("", deleteAnswer), ConfirmDeleteAnswerDialog = _s[0], confirmedDeleteAnswer = _s[1];
    var _updateAnswer = useMutation(gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n        mutation UpdateAnswer($question: ID, $id: ID, $input: CourseStageAnswerInput){\n            updateCourseStageAnswer(question: $question, answer: $id, input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation UpdateAnswer($question: ID, $id: ID, $input: CourseStageAnswerInput){\n            updateCourseStageAnswer(question: $question, answer: $id, input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var debounceUpdateAnswer = useMemo(function () { return debounce(_updateAnswer, 400); }, []);
    var updateAnswer = function (question, answer, text) {
        debounceUpdateAnswer({
            variables: {
                question: question,
                id: answer,
                input: {
                    text: text
                }
            }
        });
        setAnswers(function (answers) {
            var ans = answers.slice();
            var ix = ans.findIndex(function (x) { return x.id === answer; });
            ans[ix] = __assign(__assign({}, ans[ix]), { text: text });
            return ans;
        });
    };
    var _prev = function () {
        if (canPrev()) {
            setActiveQuestion(function (q) { return q - 1; });
        }
    };
    var canPrev = function () {
        return activeQuestion > 0;
    };
    var _next = function () {
        if (canNext()) {
            setActiveQuestion(function (q) { return q + 1; });
        }
        else {
            createQuestion({ variables: { stage: stage === null || stage === void 0 ? void 0 : stage.id, input: { text: '' } } }).then(function () {
                setActiveQuestion(function (q) { return q + 1; });
            });
        }
    };
    var canNext = function () {
        var _a;
        return activeQuestion < ((_a = stage === null || stage === void 0 ? void 0 : stage.questions) === null || _a === void 0 ? void 0 : _a.length) - 1;
    };
    var _t = useState(null), uploadProgress = _t[0], setUploadProgress = _t[1];
    return (_jsxs(Paper, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: [_jsx(ConfirmDeleteQuestionDialog, {}), _jsx(ConfirmDeleteAnswerDialog, {}), _jsx(ConfirmDelete, { open: confirm, onClose: function () { setConfirm(false); }, onSubmit: function () {
                    var _a;
                    deleteLesson({ variables: { course: (_a = props === null || props === void 0 ? void 0 : props.course) === null || _a === void 0 ? void 0 : _a.id, stage: stage === null || stage === void 0 ? void 0 : stage.id } }).then(function () {
                        navigate('../');
                        setConfirm(false);
                    });
                    // deleteCourse?.({
                    //     variables: {
                    //         id: modalOpen.id
                    //     }
                    // }).then(() => {
                    //     openModal(null);
                    //     setConfirm(false)
                    // })
                    // deleteUser({variables: {id: modalOpen?.id}}).then(() => {
                    //     openModal(null);
                    //     setConfirm(false);
                    // })
                } }), _jsxs(Box, __assign({ sx: { display: 'flex', marginTop: '12px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' } }, { children: [_jsx(Box, __assign({ sx: { flex: 1 } }, { children: _jsx(IconButton, __assign({ onClick: function () {
                                // navigate('../')
                                setConfirm(true);
                            }, color: "error" }, { children: _jsx(Delete, {}) })) })), _jsx(Box, __assign({ sx: { flex: 1 } }, { children: _jsx(TextField, { fullWidth: true, label: "Lesson name", size: "small", onChange: function (e) {
                                updateLessonName(e.target.value);
                            }, value: lessonName || '' }) })), _jsx(Box, __assign({ sx: { flex: 1, display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Button, __assign({ onClick: function () { return setEditQuestions(!editQuestion); }, variant: "contained", color: "secondary" }, { children: editQuestion ? "Lesson" : "Questions" })) }))] })), _jsx(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: editQuestion ? (_jsx(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column', paddingLeft: '10%', paddingRight: '10%' } }, { children: ((_f = stage === null || stage === void 0 ? void 0 : stage.questions) === null || _f === void 0 ? void 0 : _f.length) > 0 ? (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ sx: { display: 'flex' } }, { children: [_jsx(IconButton, __assign({ onClick: _prev, disabled: !canPrev() }, { children: _jsx(ChevronLeft, {}) })), _jsx(TextField, { fullWidth: true, value: title, onChange: function (e) {
                                            updateQuestion(e.target.value);
                                        }, label: "Question text", size: "small" }), _jsx(IconButton, __assign({ onClick: _next }, { children: !canNext() ? (_jsx(Add, {})) : (_jsx(ChevronRight, {})) })), _jsx(IconButton, __assign({ onClick: function () {
                                            confirmedDeleteQuestion({
                                                variables: {
                                                    stage: stage === null || stage === void 0 ? void 0 : stage.id,
                                                    id: question === null || question === void 0 ? void 0 : question.id
                                                }
                                            }).then(function () {
                                                setActiveQuestion(function (q) { return q - 1; });
                                            });
                                        }, color: "error" }, { children: _jsx(Delete, {}) }))] })), _jsxs(Box, __assign({ sx: { flex: 1, marginTop: '12px' } }, { children: [(_h = (_g = answers === null || answers === void 0 ? void 0 : answers.slice()) === null || _g === void 0 ? void 0 : _g.sort(function (a, b) { return a.index - b.index; })) === null || _h === void 0 ? void 0 : _h.map(function (answer) {
                                        var _a;
                                        return (_jsxs(Paper, __assign({ sx: { display: 'flex', marginBottom: '12px' } }, { children: [_jsx(Checkbox, { checked: ((_a = question === null || question === void 0 ? void 0 : question.answer) === null || _a === void 0 ? void 0 : _a.id) === (answer === null || answer === void 0 ? void 0 : answer.id), onChange: function (e) {
                                                        if (e.target.checked) {
                                                            updateQuestion(undefined, answer === null || answer === void 0 ? void 0 : answer.id);
                                                        }
                                                    } }), _jsx(TextField, { fullWidth: true, size: "small", value: answer.text, onChange: function (e) { return updateAnswer(question === null || question === void 0 ? void 0 : question.id, answer === null || answer === void 0 ? void 0 : answer.id, e.target.value); } }), _jsx(IconButton, __assign({ onClick: function () { return confirmedDeleteAnswer({ variables: { question: question === null || question === void 0 ? void 0 : question.id, id: answer === null || answer === void 0 ? void 0 : answer.id } }); }, color: "error" }, { children: _jsx(Delete, {}) }))] })));
                                    }), _jsx(Button, __assign({ onClick: function () {
                                            createAnswer({
                                                variables: {
                                                    question: question === null || question === void 0 ? void 0 : question.id,
                                                    input: {
                                                        text: ''
                                                    }
                                                }
                                            });
                                        } }, { children: "Add answer" }))] }))] })) : (_jsx(Box, __assign({ sx: { flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' } }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: [_jsx(Typography, { children: "No Questions, add some" }), _jsx(IconButton, __assign({ onClick: function () {
                                        createQuestion({ variables: { stage: stage === null || stage === void 0 ? void 0 : stage.id, input: { text: '' } } }).then(function () {
                                            setActiveQuestion(0);
                                            // setActiveQuestion((q) => q+1)
                                        });
                                    }, size: "large", sx: { marginLeft: '12px' } }, { children: _jsx(Add, { fontSize: 'inherit' }) }))] })) }))) }))) : (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: { flex: 1, display: 'flex' } }, { children: _jsx(VideoUpload, { video: (stage === null || stage === void 0 ? void 0 : stage.video) ? "".concat(API_URL, "/api/").concat((_j = props === null || props === void 0 ? void 0 : props.course) === null || _j === void 0 ? void 0 : _j.id, "/").concat(stage === null || stage === void 0 ? void 0 : stage.id) : undefined, uploadProgress: uploadProgress, onUpload: function (file) {
                                    uploadVideo(file).then(function () {
                                        client.refetchQueries({ include: ['GetCourse'] });
                                    });
                                } }) })), _jsx(Box, { sx: { flex: 1 } })] })) }))] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
