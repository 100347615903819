var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';
import { ImageUpload } from '../../components/image-upload';
import { CertificateTitle } from 'edutech-shared-components';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../App';
import { ConfirmDelete } from '../../components/modals/confirm-delete';
export var InfoEditor = function (props) {
    var _a, _b, _c;
    var client = useApolloClient();
    var _d = useState(null), confirm = _d[0], setConfirm = _d[1];
    var _e = useState({}), course = _e[0], setCourse = _e[1];
    var _f = useState([]), outcomes = _f[0], setOutcomes = _f[1];
    useEffect(function () {
        var _a;
        setOutcomes(((_a = props.course) === null || _a === void 0 ? void 0 : _a.outcomes) || []);
    }, [(_a = props.course) === null || _a === void 0 ? void 0 : _a.outcomes]);
    useEffect(function () {
        setCourse(__assign({}, props.course));
    }, [props.course]);
    var createOutcome = useMutation(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        mutation CreateOutcome($course: ID, $input: CourseOutcomeInput!) {\n            createCourseOutcome(course: $course, input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation CreateOutcome($course: ID, $input: CourseOutcomeInput!) {\n            createCourseOutcome(course: $course, input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var _updateOutcome = useMutation(gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        mutation UpdateOutcome($course: ID, $id: ID!, $input: CourseOutcomeInput!){\n            updateCourseOutcome(course: $course, id: $id, input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation UpdateOutcome($course: ID, $id: ID!, $input: CourseOutcomeInput!){\n            updateCourseOutcome(course: $course, id: $id, input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var debouncedUpdate = useMemo(function () { return debounce(_updateOutcome, 400); }, []);
    var updateOutcome = function (id, text) {
        var _a;
        debouncedUpdate({
            variables: {
                course: (_a = props.course) === null || _a === void 0 ? void 0 : _a.id,
                id: id,
                input: {
                    text: text
                }
            }
        });
        setOutcomes(function (outcomes) {
            var out = outcomes.slice();
            var ix = out.findIndex(function (a) { return a.id === id; });
            if (ix > -1) {
                out[ix] = __assign(__assign({}, out[ix]), { text: text });
            }
            return out;
        });
    };
    var removeOutcome = useMutation(gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        mutation DeleteOutcome($course: ID, $id: ID!){\n            deleteCourseOutcome(course: $course, id: $id){\n                id\n            }\n        }\n    "], ["\n        mutation DeleteOutcome($course: ID, $id: ID!){\n            deleteCourseOutcome(course: $course, id: $id){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var _updateCourse = useMutation(gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        mutation UpdateCourse($id: ID, $input: CourseInput){\n            updateCourse(id: $id, input: $input){\n                id\n            }\n        }\n    "], ["\n        mutation UpdateCourse($id: ID, $input: CourseInput){\n            updateCourse(id: $id, input: $input){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var updateCourseImage = function (file) {
        var _a;
        var fd = new FormData();
        fd.append('image', file);
        //TODO show loading indicator
        return axios.post("".concat(API_URL, "/api/").concat((_a = props.course) === null || _a === void 0 ? void 0 : _a.id, "/image"), fd, {
            withCredentials: true,
            onUploadProgress: function (progress) {
                // setUploadProgress((progress.loaded / (progress.total || 0)) * 100)
            }
        }).catch(function (err) {
            // setUploadProgress('Error uploading video')
        });
        // return fetch(`${API_URL}/api/${id}/${activeView}/upload`, {body: fd}).then(() => {
        //     console.log("Uploaded")
        // })
    };
    var debounceUpdateCourse = useMemo(function () { return debounce(_updateCourse, 400); }, []);
    var updateCourse = function (id, input) {
        var _a;
        debounceUpdateCourse({
            variables: {
                id: (_a = props.course) === null || _a === void 0 ? void 0 : _a.id,
                input: input
            }
        });
        setCourse(function (course) {
            return (__assign(__assign({}, course), input));
        });
    };
    var imageSrc = useMemo(function () {
        var _a, _b;
        if ((_a = props.course) === null || _a === void 0 ? void 0 : _a.image) {
            return "".concat(API_URL, "/api/").concat((_b = props.course) === null || _b === void 0 ? void 0 : _b.id, "/image");
        }
        return undefined;
    }, [props.course]);
    return (_jsx(Box, __assign({ sx: { flex: 1, display: 'flex' } }, { children: _jsxs(Paper, __assign({ sx: { flex: 1, display: 'flex', padding: '12px', flexDirection: 'column' } }, { children: [_jsx(ConfirmDelete, { open: Boolean(confirm), onClose: function () { setConfirm(null); }, onSubmit: function () {
                        var _a;
                        removeOutcome({ variables: { course: (_a = props.course) === null || _a === void 0 ? void 0 : _a.id, id: confirm } }).then(function () {
                            setConfirm(false);
                        });
                        // deleteCourse?.({
                        //     variables: {
                        //         id: modalOpen.id
                        //     }
                        // }).then(() => {
                        //     openModal(null);
                        //     setConfirm(false)
                        // })
                        // deleteUser({variables: {id: modalOpen?.id}}).then(() => {
                        //     openModal(null);
                        //     setConfirm(false);
                        // })
                    } }), _jsxs(Box, __assign({ sx: { display: 'flex' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', marginRight: '12px', flex: 1 } }, { children: [_jsx(TextField, { sx: { marginBottom: '12px', fontSize: '23px', fontWeight: 'bold' }, size: "small", value: (course === null || course === void 0 ? void 0 : course.name) || '', onChange: function (e) {
                                        var _a;
                                        updateCourse((_a = props.course) === null || _a === void 0 ? void 0 : _a.id, { name: e.target.value });
                                    }, label: "Name" }), _jsx(TextField, { multiline: true, sx: { marginBottom: '12px' }, size: "small", value: (course === null || course === void 0 ? void 0 : course.shortDescription) || '', onChange: function (e) {
                                        var _a;
                                        updateCourse((_a = props.course) === null || _a === void 0 ? void 0 : _a.id, { shortDescription: e.target.value });
                                    }, label: "Short Description" })] })), _jsx(ImageUpload, { src: imageSrc, onUpload: function (file) {
                                updateCourseImage(file).then(function () {
                                    client.refetchQueries({ include: ['GetCourse'] });
                                });
                            } })] })), _jsx(CertificateTitle, { title: "Certifcate Summary" }), _jsx(TextField, { sx: { marginBottom: '12px' }, size: "small", value: (course === null || course === void 0 ? void 0 : course.description) || '', multiline: true, onChange: function (e) {
                        var _a;
                        updateCourse((_a = props.course) === null || _a === void 0 ? void 0 : _a.id, { description: e.target.value });
                    }, minRows: 4 }), _jsxs(Box, { children: [_jsx(CertificateTitle, { title: "Certificate Learning Outcomes" }), (_c = (_b = outcomes === null || outcomes === void 0 ? void 0 : outcomes.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.index - b.index; })) === null || _c === void 0 ? void 0 : _c.map(function (outcome) { return (_jsxs(Box, __assign({ sx: { display: 'flex', marginBottom: '12px' } }, { children: [_jsx(TextField, { fullWidth: true, value: outcome.text, size: "small", onChange: function (e) {
                                        updateOutcome(outcome.id, e.target.value);
                                    } }), _jsx(IconButton, __assign({ color: 'error', onClick: function () {
                                        setConfirm(outcome.id);
                                    } }, { children: _jsx(Delete, {}) }))] }))); }), _jsx(Button, __assign({ onClick: function () {
                                var _a;
                                createOutcome({ variables: { course: (_a = props.course) === null || _a === void 0 ? void 0 : _a.id, input: { text: '' } } });
                            } }, { children: "Add outcome" }))] })] })) })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
