var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { globalTheme } from 'edutech-styles';
import { Container } from './container';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
var API_URL = process.env.NODE_ENV === 'production' ? 'https://api.internetlearning.io' : 'http://localhost:8030';
var client = new ApolloClient({
    uri: "".concat(API_URL, "/api?api=admin"),
    cache: new InMemoryCache(),
    credentials: "include",
});
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(React.StrictMode, { children: _jsx(ApolloProvider, __assign({ client: client }, { children: _jsx(ThemeProvider, __assign({ theme: globalTheme }, { children: _jsx(Router, { children: _jsx(Routes, { children: _jsx(Route, { path: "*", element: _jsx(Container, {}) }) }) }) })) })) }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
