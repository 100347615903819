var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import App, { API_URL } from './App';
import { Login } from './views/login';
import { Routes, Route } from 'react-router-dom';
import { CourseEditor } from './views/course-editor';
export var Container = function () {
    var _a = useState(true), loadingAuthState = _a[0], setLoadingAuthState = _a[1];
    var _b = useState(false), authenticated = _b[0], setAuthenticated = _b[1];
    var _c = useState(false), authenticating = _c[0], setAuthenticating = _c[1];
    useEffect(function () {
        fetch("".concat(API_URL, "/api/auth/info"), {
            credentials: 'include'
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            var _a;
            if (((_a = response.info) === null || _a === void 0 ? void 0 : _a.aud) === "admin.internetlearning.io") {
                setLoadingAuthState(false);
                setAuthenticated(response.error ? false : true);
            }
            else {
                setLoadingAuthState(false);
                setAuthenticated(false);
            }
        });
    }, []);
    return loadingAuthState ? (_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' } }, { children: [_jsx(CircularProgress, {}), _jsx(Typography, __assign({ sx: { marginTop: '12px' } }, { children: "Loading Internet Learning..." }))] }))) : authenticated ? (_jsxs(Routes, { children: [_jsx(Route, { path: "certificates/:id/editor/*", element: _jsx(CourseEditor, {}) }), _jsx(Route, { path: "*", element: _jsx(App, {}) })] })) : (_jsx(Login, { authenticating: authenticating, onAuthenticating: function (authenticating, authenticated) {
            setAuthenticated(authenticated);
            setAuthenticating(authenticating);
        } }));
};
