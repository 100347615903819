var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
// import { title } from 'process';
import React, { useState } from 'react';
// import { ConfirmDelete } from '../components/modals/confirm-delete';
export var useConfirm = function (message, fn) {
    var _a = useState(null), promise = _a[0], setPromise = _a[1];
    var confirm = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return new Promise(function (resolve, reject) {
            setPromise({ resolve: resolve });
        }).then(function () {
            return fn === null || fn === void 0 ? void 0 : fn.apply(void 0, args);
        });
    };
    var handleClose = function () {
        setPromise(null);
    };
    var handleConfirm = function () {
        promise === null || promise === void 0 ? void 0 : promise.resolve(true);
        handleClose();
    };
    var handleCancel = function () {
        promise === null || promise === void 0 ? void 0 : promise.resolve(false);
        handleClose();
    };
    var ConfirmDialog = function () {
        return (_jsxs(Dialog, __assign({ open: promise !== null, fullWidth: true }, { children: [_jsx(DialogTitle, { children: "Are you sure" }), _jsx(DialogContent, __assign({ sx: { padding: 0 } }, { children: _jsx(Typography, __assign({ sx: { margin: '12px' } }, { children: "Are you sure you want to delete this item?" })) })), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: handleCancel }, { children: "No" })), _jsx(Button, __assign({ variant: "contained", color: "error", onClick: handleConfirm }, { children: "Yes" }))] })] })));
    };
    return [ConfirmDialog, confirm];
};
