var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Checkbox, Divider, IconButton, List, ListItem, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import { CourseStageModal } from '../../components/modals/course-stage';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { VideoUpload } from '../../components/video-upload';
import { Delete } from '@mui/icons-material';
import { debounce } from 'lodash';
import axios from 'axios';
var API_URL = process.env.NODE_ENV === 'production' ? 'https://api.internetlearning.io' : 'http://localhost:8030';
export var CourseSingle = function () {
    var _a, _b, _c;
    var id = useParams().id;
    var _d = useState(false), modalOpen = _d[0], openModal = _d[1];
    var courseData = useQuery(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query GetCourseData($id: String) {\n            courses(where: {ids: [$id]}){\n                id\n                name\n\n                stages {\n                    id\n                    name\n\n                    index\n\n                    video \n                    courseText\n\n                    questionText\n\n                    questions {\n                        id\n                        text\n\n                        index\n                    }\n\n                    answer {\n                        id\n                    }\n                }\n            }\n        }\n    "], ["\n        query GetCourseData($id: String) {\n            courses(where: {ids: [$id]}){\n                id\n                name\n\n                stages {\n                    id\n                    name\n\n                    index\n\n                    video \n                    courseText\n\n                    questionText\n\n                    questions {\n                        id\n                        text\n\n                        index\n                    }\n\n                    answer {\n                        id\n                    }\n                }\n            }\n        }\n    "]))), {
        variables: {
            id: id
        }
    }).data;
    var course = ((_a = courseData === null || courseData === void 0 ? void 0 : courseData.courses) === null || _a === void 0 ? void 0 : _a[0]) || {};
    var stages = (course === null || course === void 0 ? void 0 : course.stages) || [];
    var _e = useState(null), activeView = _e[0], setActiveView = _e[1];
    var _f = useState('course'), view = _f[0], setView = _f[1];
    var activeStage = useMemo(function () {
        var _a;
        return (_a = course === null || course === void 0 ? void 0 : course.stages) === null || _a === void 0 ? void 0 : _a.find(function (a) { return a.id === activeView; });
    }, [activeView, course === null || course === void 0 ? void 0 : course.stages]);
    var _g = useState((activeStage === null || activeStage === void 0 ? void 0 : activeStage.questions) || []), questions = _g[0], setQuestions = _g[1];
    var _h = useState((activeStage === null || activeStage === void 0 ? void 0 : activeStage.questionText) || ''), questionText = _h[0], setQuestionText = _h[1];
    var _j = useState((activeStage === null || activeStage === void 0 ? void 0 : activeStage.courseText) || ''), courseText = _j[0], setCourseText = _j[1];
    useEffect(function () {
        setQuestions((activeStage === null || activeStage === void 0 ? void 0 : activeStage.questions) || []);
        setQuestionText((activeStage === null || activeStage === void 0 ? void 0 : activeStage.questionText) || '');
        setCourseText((activeStage === null || activeStage === void 0 ? void 0 : activeStage.courseText) || '');
    }, [activeStage]);
    var createStage = useMutation(gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        mutation CreateCourseStage ($course: ID, $name: String) {\n            createCourseStage(course: $course, input: {name: $name}){\n                id\n            }\n        }\n    "], ["\n        mutation CreateCourseStage ($course: ID, $name: String) {\n            createCourseStage(course: $course, input: {name: $name}){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourseData']
    })[0];
    var setStageAnswer = useMutation(gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        mutation SetStageAnswer($course: ID, $stage: ID, $answer: ID){\n            updateCourseStage(course: $course, stage: $stage, input: {answerId: $answer}){\n                id\n            }\n        }\n    "], ["\n        mutation SetStageAnswer($course: ID, $stage: ID, $answer: ID){\n            updateCourseStage(course: $course, stage: $stage, input: {answerId: $answer}){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourseData']
    })[0];
    var updateStageQuestionText = useMutation(gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        mutation UpdateCourseStage ($course: ID, $stage: ID, $questionText: String){\n            updateCourseStage(course: $course, stage: $stage, input: {questionText: $questionText}) {\n                id\n            }\n        }\n    "], ["\n        mutation UpdateCourseStage ($course: ID, $stage: ID, $questionText: String){\n            updateCourseStage(course: $course, stage: $stage, input: {questionText: $questionText}) {\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourseData']
    })[0];
    var updateStageCourseText = useMutation(gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation UpdateCourseStage ($course: ID, $stage: ID, $courseText: String){\n        updateCourseStage(course: $course, stage: $stage, input: {courseText: $courseText}) {\n            id\n        }\n    }\n"], ["\n    mutation UpdateCourseStage ($course: ID, $stage: ID, $courseText: String){\n        updateCourseStage(course: $course, stage: $stage, input: {courseText: $courseText}) {\n            id\n        }\n    }\n"]))), {
        refetchQueries: ['GetCourseData']
    })[0];
    var createStageQuestion = useMutation(gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        mutation CreateStageQuestion ($course: ID, $stage: ID){\n            createCourseStageQuestion(course: $course, stage: $stage){\n                id\n            }\n        }\n    "], ["\n        mutation CreateStageQuestion ($course: ID, $stage: ID){\n            createCourseStageQuestion(course: $course, stage: $stage){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourseData'],
        variables: {
            course: id,
            stage: activeView
        }
    })[0];
    var updateStageQuestion = useMutation(gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        mutation UpdateStageQuestion ($course: ID, $stage: ID, $question: ID, $text: String){\n            updateCourseStageQuestion(course: $course, stage: $stage, question: $question, input: {text: $text}){\n                id\n            }\n        }\n    "], ["\n        mutation UpdateStageQuestion ($course: ID, $stage: ID, $question: ID, $text: String){\n            updateCourseStageQuestion(course: $course, stage: $stage, question: $question, input: {text: $text}){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetCourseData'],
    })[0];
    var debounceUpdateStageQuestion = useMemo(function () { return debounce(updateStageQuestion, 500); }, []);
    var debounceUpdateCourseText = useMemo(function () { return debounce(updateStageCourseText, 500); }, []);
    var debounceUpdateQuestionText = useMemo(function () { return debounce(updateStageQuestionText, 500); }, []);
    var _k = useState(null), uploadProgress = _k[0], setUploadProgress = _k[1];
    var uploadVideo = function (file) {
        var fd = new FormData();
        fd.append('video', file);
        //TODO show loading indicator
        return axios.post("".concat(API_URL, "/api/").concat(id, "/").concat(activeView, "/upload"), fd, {
            onUploadProgress: function (progress) {
                setUploadProgress((progress.loaded / (progress.total || 0)) * 100);
            }
        }).catch(function (err) {
            setUploadProgress('Error uploading video');
        });
        // return fetch(`${API_URL}/api/${id}/${activeView}/upload`, {body: fd}).then(() => {
        //     console.log("Uploaded")
        // })
    };
    var renderCourseEditor = function () {
        return (_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column', marginTop: '12px' } }, { children: [_jsx(Box, __assign({ sx: { flex: 0.7, marginBottom: '12px', display: 'flex' } }, { children: _jsx(VideoUpload, { video: activeStage === null || activeStage === void 0 ? void 0 : activeStage.video, uploadProgress: uploadProgress, onUpload: function (file) {
                            uploadVideo(file);
                        } }) })), _jsx(Box, __assign({ sx: { display: 'flex', flex: 0.3, paddingLeft: '15%', paddingRight: '15%', justifyContent: 'center' } }, { children: _jsx(TextField, { value: courseText || '', fullWidth: true, minRows: 4, onChange: function (e) {
                            //TODO debounce this
                            debounceUpdateCourseText({
                                variables: {
                                    course: id,
                                    stage: activeView,
                                    courseText: e.target.value
                                }
                            });
                            setCourseText(e.target.value);
                        }, label: "Course text", multiline: true }) }))] })));
    };
    var renderCourseQuestions = function () {
        // const questions = (activeStage?.questions || []).slice();
        var _a, _b;
        return (_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: [_jsx(TextField, { fullWidth: true, multiline: true, minRows: 4, value: questionText, onChange: function (e) {
                        //TODO debounce this
                        debounceUpdateQuestionText({
                            variables: {
                                course: id,
                                stage: activeView,
                                questionText: e.target.value
                            }
                        });
                        setQuestionText(e.target.value);
                    }, label: "Question" }), _jsx(Typography, { children: "Answers" }), _jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: [(_b = (_a = (questions || []).slice()) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) { return ((a === null || a === void 0 ? void 0 : a.index) || 0) - ((b === null || b === void 0 ? void 0 : b.index) || 0); })) === null || _b === void 0 ? void 0 : _b.map(function (question, ix) {
                            var _a;
                            return (_jsxs(Paper, __assign({ sx: { display: 'flex', padding: '4px', marginBottom: '6px' } }, { children: [_jsx(Checkbox, { checked: question.id === ((_a = activeStage.answer) === null || _a === void 0 ? void 0 : _a.id), onChange: function (e) { return setStageAnswer({ variables: { course: id, stage: activeView, answer: question.id } }); } }), _jsx(TextField, { fullWidth: true, size: "small", onChange: function (e) {
                                            //TODO debounce
                                            debounceUpdateStageQuestion({
                                                variables: {
                                                    course: id,
                                                    stage: activeView,
                                                    question: question.id,
                                                    text: e.target.value
                                                }
                                            });
                                            setQuestions(function (questions) {
                                                var newQuestions = questions.slice();
                                                var item = __assign({}, newQuestions[ix]);
                                                item.text = e.target.value;
                                                newQuestions[ix] = item;
                                                return newQuestions;
                                            });
                                        }, value: question.text }), _jsx(IconButton, __assign({ color: "error" }, { children: _jsx(Delete, {}) }))] }), ix));
                        }), _jsx(Button, __assign({ onClick: function () {
                                createStageQuestion();
                            } }, { children: "Add Answer" }))] }))] })));
    };
    return (_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: [_jsx(CourseStageModal, { open: modalOpen, onSubmit: function (stage) {
                    createStage({ variables: { name: stage.name, course: id } }).then(function () {
                        openModal(false);
                    });
                }, onClose: function () {
                    openModal(false);
                } }), _jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } }, { children: [_jsx(Box, {}), _jsxs(Typography, { children: [course === null || course === void 0 ? void 0 : course.name, " - ", activeStage === null || activeStage === void 0 ? void 0 : activeStage.name] }), _jsxs(Tabs, __assign({ value: view, onChange: function (evt, newValue) {
                            setView(newValue);
                        } }, { children: [_jsx(Tab, { value: "course", label: "Course" }), _jsx(Tab, { value: "questions", label: "Questions" })] }))] })), _jsx(Divider, {}), _jsxs(Box, __assign({ sx: { flex: 1, display: 'flex' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', marginRight: '6px' } }, { children: [_jsx(List, { children: (_c = (_b = stages === null || stages === void 0 ? void 0 : stages.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return ((a === null || a === void 0 ? void 0 : a.index) || 0) - ((b === null || b === void 0 ? void 0 : b.index) || 0); })) === null || _c === void 0 ? void 0 : _c.map(function (stage) { return (_jsx(ListItem, __assign({ sx: { padding: 0, marginBottom: '6px' }, button: true, onClick: function () {
                                        setActiveView(stage.id);
                                    } }, { children: _jsx(Paper, __assign({ sx: { flex: 1, padding: '6px' } }, { children: _jsxs(Typography, { children: [stage.index, " - ", stage.name] }) })) }))); }) }), _jsx(Button, __assign({ onClick: function () {
                                    openModal(true);
                                } }, { children: "Add stage" }))] })), _jsx(Divider, { orientation: 'vertical' }), activeView ?
                        (view === 'course' ? renderCourseEditor() : renderCourseQuestions()) :
                        (_jsx(Box, __assign({ sx: { display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' } }, { children: _jsx(Typography, { children: "Select a stage from the left hand side..." }) })))] }))] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
