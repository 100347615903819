var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Routes, Route, useNavigate, Outlet } from 'react-router-dom';
import { Paper, Box, List, ListItem } from '@mui/material';
import './App.css';
import { CourseView, UserView } from './views';
import { CourseSingle } from './views/courses/single';
import { UserSingle } from './views/users/single';
import { Header } from './components/header';
export var API_URL = process.env.NODE_ENV === 'production' ? 'https://api.internetlearning.io' : 'http://localhost:8030';
function App() {
    var navigate = useNavigate();
    var menuItems = [
        {
            label: "Users",
            path: 'users',
            component: _jsx(Outlet, {}),
            children: [
                {
                    path: '',
                    component: _jsx(UserView, {})
                },
                {
                    path: ':id',
                    component: _jsx(UserSingle, {})
                }
            ]
        },
        // {
        //   label: "Corporations",
        //   path: "corporations",
        //   component: <CorporationView />
        // },
        {
            label: "Certificates",
            path: 'certificates',
            component: _jsx(Outlet, {}),
            children: [
                {
                    path: '',
                    component: _jsx(CourseView, {})
                },
                {
                    path: ':id',
                    component: _jsx(CourseSingle, {})
                }
            ]
        },
        // {
        //   label: "Coupons",
        //   path: 'coupons',
        //   component: <CouponView />
        // }
    ];
    return (_jsxs(Box, __assign({ sx: { flex: 1, flexDirection: 'column', background: '#eefaff' }, className: "App" }, { children: [_jsx(Header, {}), _jsxs(Box, __assign({ sx: { flex: 1, display: 'flex' } }, { children: [_jsx(Paper, __assign({ sx: { minWidth: '175px' } }, { children: _jsx(List, { children: menuItems.map(function (menu_item) {
                                return (_jsx(ListItem, __assign({ onClick: function () { return navigate(menu_item.path); }, button: true }, { children: menu_item.label })));
                            }) }) })), _jsx(Box, __assign({ sx: { flex: 1, display: 'flex', padding: '6px', paddingLeft: '8vw', paddingRight: '8vw' } }, { children: _jsx(Routes, { children: menuItems.map(function (menu_item) {
                                var _a;
                                return (_jsx(Route, __assign({ path: menu_item.path, element: menu_item.component }, { children: (_a = menu_item.children) === null || _a === void 0 ? void 0 : _a.map(function (child) { return (_jsx(Route, { path: child.path, element: child.component })); }) })));
                            }) }) }))] }))] })));
}
export default App;
