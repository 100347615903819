var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import { Divider, Paper } from '@mui/material';
import { List } from '@mui/material';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
export var UserSingle = function () {
    var _a, _b, _c, _d;
    var id = useParams().id;
    var data = useQuery(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query UserData($id: String) {\n            users(where: {ids: [$id]}){\n                id\n                name\n\n                email\n\n                registeredCertificates {\n                    id\n                    name\n                }\n\n                completedCertificates {\n                    id\n                    name\n                }\n\n                purchasedCertificates{\n                    id \n                    name\n                }\n            }\n        }\n    "], ["\n        query UserData($id: String) {\n            users(where: {ids: [$id]}){\n                id\n                name\n\n                email\n\n                registeredCertificates {\n                    id\n                    name\n                }\n\n                completedCertificates {\n                    id\n                    name\n                }\n\n                purchasedCertificates{\n                    id \n                    name\n                }\n            }\n        }\n    "]))), {
        variables: {
            id: id
        }
    }).data;
    var user = ((_a = data === null || data === void 0 ? void 0 : data.users) === null || _a === void 0 ? void 0 : _a[0]) || {};
    return (_jsxs(Paper, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column', padding: '24px' } }, { children: [_jsxs(Box, __assign({ sx: { marginBottom: '6px' } }, { children: [_jsx(Typography, __assign({ fontSize: "lg", fontWeight: "bold" }, { children: user === null || user === void 0 ? void 0 : user.name })), _jsx(Typography, { children: user === null || user === void 0 ? void 0 : user.email })] })), _jsx(Divider, {}), _jsxs(Box, __assign({ sx: { flex: 1, marginTop: '6px' } }, { children: [_jsx(Typography, __assign({ fontWeight: "bold" }, { children: "Registered Certificates" })), _jsx(List, { children: (_b = user === null || user === void 0 ? void 0 : user.registeredCertificates) === null || _b === void 0 ? void 0 : _b.map(function (certificate) { return (_jsx(Box, { children: certificate === null || certificate === void 0 ? void 0 : certificate.name })); }) })] })), _jsxs(Box, __assign({ sx: { flex: 1 } }, { children: [_jsx(Typography, __assign({ fontWeight: "bold" }, { children: "Completed Certificates" })), _jsx(List, { children: (_c = user === null || user === void 0 ? void 0 : user.completedCertificates) === null || _c === void 0 ? void 0 : _c.map(function (certificate) { return (_jsx(Box, { children: certificate === null || certificate === void 0 ? void 0 : certificate.name })); }) })] })), _jsxs(Box, __assign({ sx: { flex: 1 } }, { children: [_jsx(Typography, __assign({ fontWeight: "bold" }, { children: "Purchased Certificates" })), _jsx(List, { children: (_d = user === null || user === void 0 ? void 0 : user.purchasedCertificates) === null || _d === void 0 ? void 0 : _d.map(function (certificate) { return (_jsx(Box, { children: certificate === null || certificate === void 0 ? void 0 : certificate.name })); }) })] }))] })));
};
var templateObject_1;
