var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloudUpload } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import React, { useCallback } from 'react';
import { LinearProgress } from '@mui/material';
export var VideoUpload = function (props) {
    var onDrop = useCallback(function (acceptedFiles) {
        var _a;
        // Do something with the files
        console.log({ acceptedFiles: acceptedFiles });
        if (acceptedFiles === null || acceptedFiles === void 0 ? void 0 : acceptedFiles[0]) {
            (_a = props.onUpload) === null || _a === void 0 ? void 0 : _a.call(props, acceptedFiles === null || acceptedFiles === void 0 ? void 0 : acceptedFiles[0]);
            // setPreview(URL.createObjectURL(acceptedFiles?.[0]))
        }
    }, [props.onUpload]);
    var _a = useDropzone({ onDrop: onDrop, accept: { 'video/mp4': ['.mp4'] } }), getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, isDragActive = _a.isDragActive;
    console.log(props.uploadProgress);
    return (_jsx(Box, __assign({ sx: { display: 'flex', flex: 1, justifyContent: 'center' } }, { children: _jsxs(Paper, __assign({}, getRootProps(), { sx: {
                // flex: 1,
                position: 'relative',
                overflow: 'hidden',
                aspectRatio: '16/9',
                background: 'black',
                display: 'flex',
                borderRadius: '12px',
                opacity: isDragActive ? '0.6' : '1',
                border: isDragActive ? '2px solid blue' : undefined,
            } }, { children: [_jsx("input", __assign({}, getInputProps())), props.video ? (_jsx("video", { style: {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                    }, controls: true, src: props.video })) : (_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' } }, { children: [_jsx(CloudUpload, { fontSize: 'large', sx: { color: 'white' } }), _jsxs(Typography, __assign({ sx: { color: 'white' } }, { children: [isDragActive ? "Drop" : "Drag and drop", " video here or click to open file selector"] }))] })), props.uploadProgress && typeof (props.uploadProgress) !== 'string' ? _jsx(LinearProgress, { sx: { height: '12px' }, variant: 'determinate', color: "secondary", value: props.uploadProgress }) : _jsx(Typography, __assign({ color: "error" }, { children: props.uploadProgress }))] })))] })) })));
};
