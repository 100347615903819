var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
export var CourseStageModal = function (props) {
    var _a = useState({}), course = _a[0], setCourse = _a[1];
    var onSubmit = function () {
        var _a;
        (_a = props.onSubmit) === null || _a === void 0 ? void 0 : _a.call(props, course);
    };
    return (_jsxs(Dialog, __assign({ fullWidth: true, open: props.open, onClose: props.onClose }, { children: [_jsx(DialogTitle, { children: "Course Stage" }), _jsx(DialogContent, __assign({ sx: { display: 'flex', marginTop: '6px', flexDirection: 'column' } }, { children: _jsx(Box, __assign({ sx: { flex: 1, padding: '6px', display: 'flex', flexDirection: 'column' } }, { children: _jsx(TextField, { value: course.name, size: "small", onChange: function (e) { return setCourse(__assign(__assign({}, course), { name: e.target.value })); }, label: "Name" }) })) })), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: props.onClose }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: onSubmit, variant: "contained" }, { children: "Save" }))] })] })));
};
