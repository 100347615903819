var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { UserModal } from '../../components/modals/user';
import React, { useState } from 'react';
import { ListView } from '../../components';
import { useNavigate } from 'react-router-dom';
import { ConfirmDelete } from '../../components/modals/confirm-delete';
export var UserView = function () {
    var navigate = useNavigate();
    var _a = useState(false), confirm = _a[0], setConfirm = _a[1];
    var _b = useState(null), modalOpen = _b[0], openModal = _b[1];
    var data = useQuery(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query GetUsers {\n            users {\n                id\n                name\n                email\n            }\n        }\n    "], ["\n        query GetUsers {\n            users {\n                id\n                name\n                email\n            }\n        }\n    "])))).data;
    var createUser = useMutation(gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        mutation CreateUser($name: String, $email: String, $password: String) {\n            createUser(input: {email: $email, name: $name, password: $password}){\n                id\n            }\n        }\n    "], ["\n        mutation CreateUser($name: String, $email: String, $password: String) {\n            createUser(input: {email: $email, name: $name, password: $password}){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetUsers']
    })[0];
    var updateUser = useMutation(gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        mutation CreateUser($id: ID, $name: String, $email: String, $password: String) {\n            updateUser(id: $id, input: {email: $email, name: $name, password: $password}){\n                id\n            }\n        }\n    "], ["\n        mutation CreateUser($id: ID, $name: String, $email: String, $password: String) {\n            updateUser(id: $id, input: {email: $email, name: $name, password: $password}){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetUsers']
    })[0];
    var deleteUser = useMutation(gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        mutation DeleteUser($id: ID) {\n            deleteUser(id: $id){\n                id\n            }\n        }\n    "], ["\n        mutation DeleteUser($id: ID) {\n            deleteUser(id: $id){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetUsers']
    })[0];
    var users = (data === null || data === void 0 ? void 0 : data.users) || [];
    return (_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: [_jsx(ConfirmDelete, { open: confirm, onClose: function () { setConfirm(false); }, onSubmit: function () {
                    deleteUser({ variables: { id: modalOpen === null || modalOpen === void 0 ? void 0 : modalOpen.id } }).then(function () {
                        openModal(null);
                        setConfirm(false);
                    });
                } }), _jsx(UserModal, { onSubmit: function (user) {
                    if (user.id) {
                        updateUser({ variables: { id: user.id, name: user.name, email: user.email, password: user.password } }).then(function () {
                            // refetch
                            openModal(null);
                        });
                    }
                    else {
                        createUser({ variables: { name: user.name, email: user.email, password: user.password } }).then(function () {
                            // refetch
                            openModal(null);
                        });
                    }
                }, onClose: function () { return openModal(null); }, open: Boolean(modalOpen), selected: modalOpen, onDelete: (modalOpen === null || modalOpen === void 0 ? void 0 : modalOpen.id) && (function () {
                    setConfirm(true);
                    // openModal(null)
                }) }), _jsx(ListView, { cols: [
                    {
                        key: 'name',
                        label: "Name"
                    },
                    {
                        key: 'email',
                        label: "E-mail"
                    },
                ], onSelect: function (item) {
                    navigate(item.id);
                }, onEdit: function (item) {
                    openModal(item);
                }, onCreate: function () {
                    openModal({});
                }, rows: users })] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
